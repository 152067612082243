<template>
    <div class="font-stolzl">
        <div class="min-h-screen mb-10">
            
            <!-- SPACE-->
            <div id="space" class="md:h-16"></div>
            <!-- SPACE-->
            
            
            <!-- HEADING-->
            <div  class="w-auto h-auto overflow-hidden">
              <div :ref="setRef" data-anim="0" class="break-words font-light text-coolGray-900 mt-5 md:mt-28 mb-5 text-5xl px-4 md:px-8 opacity-0">Widerrufsbelehrung</div>
            </div>
            <!-- HEADING-->
            
            <!-- CONTENT-->
            <pre class="font-stolzl w-full md:w-3/4 px-4 md:px-8 font-light text-base mt-5 text-coolGray-900 whitespace-pre-line">
            Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.
            
            Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat.
            
            Um Ihr Widerrufsrecht auszuüben, müssen Sie uns:
            
            rewhite-climbing GmbH
            Kronsaalsweg 70-74
            22525, Hamburg
            E-Mail-Adresse: hello@rewhite-climbing.de
            ‍
            mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
            ‍
            Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
            
            ‍FOLGEN DES WIDERRUFS
            
            ‍Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und bis spätestens binnen vierzehn Tage ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrages bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
            
            Im Falle eines Teilwiderrufes und der damit einhergehenden Unterschreitung der Mindestbestellgrenze, die versandkostenfrei gehandhabt wurde, erfolgt eine Rückzahlung abzüglich der für die nicht widerrufenen Artikel in Höhe der überlicherweise fälligen Versandkosten, die unter der Seite "Versand" einsehbar sind.
            
            Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
            
            Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrages unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden.
            
            Sie tragen die unmittelbaren Kosten der Rücksendung der Waren. Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zurPrüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.
            
            Ende der Widerrufsbelehrung
            
            ‍WIDERRUFSFORMULAR
            
            ‍------------------------------------------------------------------------------------------(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)
            
            An rewhite-climbing GmbH
            Kronsaalsweg 70-74
            22525, Hamburg
            per Email: hello@rewhite-climbing.de
            
            Hiermit widerrufe(n) ich/wir den von mir/uns abgeschlossenen Vertrag über den Kauf der folgenden Waren:
            ‍
            ________________________________________________________________________
            
            bestellt am/erhalten am
            
            _____________________________________________________
            
            Name des/der Verbraucher(s)
            
            ________________________________________________
            
            Anschrift des/der Verbraucher(s)
            
            ______________________________________________
            
            
            
            ________________________________________________________________________
            Datum, Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
            </pre>
            <!-- CONTENT-->
            
        </div>
        <Footer />
    </div>
</template>


<script>
import Footer from '../components/footer.vue';
import scrollToTop from '../mixins/scrollToTop.vue';
import intersectionObserver from '../mixins/intersectionObserver.vue';

export default {
  name: 'Widerruf',
  components: { Footer },
  mixins: [ scrollToTop, intersectionObserver ],
}
</script>